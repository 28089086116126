import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Footer from '../component/Footer';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function MobileAppDevelopment() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <ReactLenis
            root
            options={{ gestureOrientataion: "both" }}
        >
            <Helmet>
                <title>Mobile App Development - MakeMyWebsite.Live | Expert App Development in India</title>

                <meta name="description"
                    content="Looking for top-notch mobile app development services in India? MakeMyWebsite.Live offers custom mobile app development solutions using React Native and other cutting-edge technologies. Whether you need an Android or iOS app, our expert developers provide tailored solutions to help your business grow." />

                <meta name="keywords"
                    content="mobile app development India, React Native app development, Android app development, iOS app development, custom app development services, mobile app solutions, mobile app development company, app development in India, affordable mobile apps, mobile application development company, cross-platform app development" />

                <meta name="author" content="Devsol" />
                <link rel="icon" href="https://www.makemywebsite.live/logo.ico" />
                <meta name="theme-color" content="#000000" />

                <meta property="og:site_name" content="Make My Website" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Mobile App Development - MakeMyWebsite.Live" />
                <meta property="og:description"
                    content="Get high-quality mobile app development services from MakeMyWebsite.Live. We specialize in creating custom mobile apps for Android and iOS using React Native and other advanced technologies. Contact us for affordable app development solutions tailored to your business." />
                <meta property="og:url" content="https://www.makemywebsite.live/mobile-app-development" />
                <meta property="og:image" content="https://www.makemywebsite.live/logo.png" />
                <meta property="og:image:alt" content="Mobile App Development - Make My Website" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="1200" />
                <meta property="og:location" content="Worldwide" />
                <meta property="og:locale" content="en_GB" />

                <meta property="twitter:site" content="makemywebsite" />
                <meta property="twitter:creator" content="devsol" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Mobile App Development - MakeMyWebsite.Live" />
                <meta name="twitter:description"
                    content="MakeMyWebsite.Live offers expert mobile app development services using React Native, Android, and iOS. Our custom app solutions help your business reach new heights. Get in touch today for affordable mobile app development." />
                <meta name="twitter:image" content="https://www.makemywebsite.live/logo.png" />
                <meta name="twitter:image:src" content="https://www.makemywebsite.live/logo.png" />
                <meta property="twitter:image:width" content="1200" />
                <meta property="twitter:image:height" content="1200" />

                <meta name="referrer" content="origin-when-crossorigin" id="meta_referrer" />
            </Helmet>

            <section className='inner-banner-section p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='left-section'>
                                <h1>Mobile App Development</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item"><Link to="/">Services</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item active" aria-current="page">Mobile App Development</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='service-detail-sectiom-inner top bottom p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='banner-img-box'>
                                <img src={require('../assets/img/service-details-img.jpg')} />
                            </div>
                            <div className='detail-service-page'>
                                <h2>Mobile App Development Services by Makemywebsite</h2>
                                <p>In today’s fast-paced digital world, having a robust and user-friendly mobile application is essential for businesses to connect with their customers seamlessly. At Makemywebsite, we specialize in crafting custom mobile apps that elevate your business to new heights.</p>
                                <h3>Why Choose Makemywebsite for Mobile App Development?</h3>
                                <ul>
                                    <li><strong>Custom Solutions:</strong> We design and develop mobile apps tailored specifically to your business needs and target audience.</li>
                                    <li><strong>Cross-Platform Expertise:</strong> Whether you need an app for Android, iOS, or both, we deliver top-notch solutions to ensure maximum reach.</li>
                                    <li><strong>User-Centric Designs:</strong> Our apps are built with intuitive designs and smooth functionality, ensuring a seamless experience for your users.</li>
                                    <li><strong>Scalable and Secure:</strong> We develop scalable apps equipped with robust security features to support your business growth.</li>
                                </ul>
                                <h3>Our Mobile App Development Services</h3>
                                <ul>
                                    <li><strong>iOS App Development:</strong> Transform your ideas into stunning iOS applications that captivate Apple users.</li>
                                    <li><strong>Android App Development:</strong> Reach millions of Android users with apps designed to perform flawlessly across all devices.</li>
                                    <li><strong>Hybrid & Cross-Platform Apps:</strong> Save time and resources with apps that work seamlessly on multiple platforms.</li>
                                    <li><strong>UI/UX Design</strong> Our team ensures your app not only looks great but also provides an engaging user experience.</li>
                                    <li><strong>App Maintenance and Support:</strong> We offer ongoing support and updates to keep your app running smoothly and securely.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='about-section-inner-1 p-l-r top bottom'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-6'>
                            <div className='right-section'>
                                <div className='img_left_d'>
                                    <img src={require('../assets/img/Mobile App Development.jpg')} />
                                </div>
                                <div className='shap_box'>
                                    <img src={require('../assets/img/shape-16.png')} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='left-section'>
                                <h4 className='sub-title'>The Makemywebsite Advantage</h4>
                                <h3 className='heading'>
                                    When you partner with Makemywebsite, you get:
                                </h3>
                                <ul>
                                    <li>
                                        <h4>Experienced Developers </h4>
                                        <p>who stay updated with the latest trends and technologies.</p>
                                    </li>
                                    <li>
                                        <h4>Transparent Processes </h4>
                                        <p>with clear timelines and progress updates.</p>
                                    </li>
                                    <li>
                                        <h4>Affordable Pricing  </h4>
                                        <p>without compromising on quality.</p>
                                    </li>

                                </ul>
                                <Link to='/contact' className='common_btn'>
                                    Contact Us <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </ReactLenis>
    )
}
