import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Footer from '../component/Footer';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function AWSHosting() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <ReactLenis
            root
            options={{ gestureOrientataion: "both" }}
        >

            <Helmet>
                <title>Mobile AWS Hosting - MakeMyWebsite.Live | Scalable and Secure Hosting Solutions</title>

                <meta name="description"
                    content="Discover the power of Mobile AWS Hosting with MakeMyWebsite.Live. We provide secure, scalable, and cost-effective hosting solutions powered by Amazon Web Services. Optimize your mobile app’s performance, security, and scalability with AWS infrastructure, ensuring a seamless user experience. Contact us today for expert mobile AWS hosting services in India." />

                <meta name="keywords"
                    content="mobile app hosting, AWS mobile hosting, scalable hosting solutions, mobile app performance optimization, AWS hosting India, mobile hosting with AWS, secure mobile app hosting, affordable mobile hosting services, mobile hosting Gurugram, AWS mobile hosting India" />

                <meta name="author" content="Devsol" />
                <link rel="icon" href="https://www.makemywebsite.live/logo.ico" />
                <meta name="theme-color" content="#000000" />

                <meta property="og:site_name" content="Make My Website" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Mobile AWS Hosting - MakeMyWebsite.Live" />
                <meta property="og:description"
                    content="Optimize your mobile app hosting with AWS. MakeMyWebsite.Live offers scalable, secure, and reliable mobile app hosting solutions powered by Amazon Web Services. Ensure fast, smooth, and cost-effective hosting for your mobile applications." />
                <meta property="og:url" content="https://www.makemywebsite.live/aws-hosting" />
                <meta property="og:image" content="https://www.makemywebsite.live/logo.png" />
                <meta property="og:image:alt" content="Make My Website Mobile AWS Hosting" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="1200" />
                <meta property="og:location" content="Worldwide" />
                <meta property="og:locale" content="en_GB" />

                <meta property="twitter:site" content="makemywebsite" />
                <meta property="twitter:creator" content="devsol" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Mobile AWS Hosting - MakeMyWebsite.Live" />
                <meta name="twitter:description"
                    content="Looking for secure and scalable mobile app hosting? MakeMyWebsite.Live offers AWS-powered hosting solutions that ensure optimal performance and security for your mobile apps. Contact us today to get started!" />
                <meta name="twitter:image" content="https://www.makemywebsite.live/logo.png" />
                <meta name="twitter:image:src" content="https://www.makemywebsite.live/logo.png" />
                <meta property="twitter:image:width" content="1200" />
                <meta property="twitter:image:height" content="1200" />

                <meta name="referrer" content="origin-when-crossorigin" id="meta_referrer" />
            </Helmet>

            <section className='inner-banner-section p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='left-section'>
                                <h1>AWS Hosting</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item"><Link to="/">Services</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item active" aria-current="page">AWS Hosting</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='service-detail-sectiom-inner top bottom p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='banner-img-box'>
                                <img src={require('../assets/img/service-details-img.jpg')} />
                            </div>
                            <div className='detail-service-page'>
                                <h2>AWS Hosting Solutions by Makemywebsite</h2>
                                <p>At Makemywebsite, we provide top-notch AWS hosting services tailored to meet your business needs. As an industry-leading cloud hosting platform, Amazon Web Services (AWS) offers unmatched scalability, reliability, and performance, making it the perfect choice for businesses of all sizes.</p>
                                <h3>Why Choose AWS Hosting?</h3>
                                <ul>
                                    <li><strong>Scalability:</strong> Seamlessly scale your resources as your business grows. AWS adapts to your needs, whether you're running a small website or a large-scale application.</li>
                                    <li><strong>Reliability:</strong> With AWS’s global infrastructure, enjoy minimal downtime and robust disaster recovery options.</li>
                                    <li><strong>Performance:</strong> Ensure lightning-fast speeds for your website or application with AWS's cutting-edge technology.</li>
                                    <li><strong>Security:</strong> Benefit from advanced encryption and compliance standards to protect your data.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='about-section-inner-1 p-l-r top bottom'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-6'>
                            <div className='right-section'>
                                <div className='img_left_d'>
                                    <img src={require('../assets/img/AWS Hosting.jpg')} />
                                </div>
                                <div className='shap_box'>
                                    <img src={require('../assets/img/shape-16.png')} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='left-section'>
                                <h4 className='sub-title'>Our AWS Hosting Services</h4>
                                <h3 className='heading'>
                                    At Makemywebsite, we offer:
                                </h3>
                                <ul>
                                    <li>
                                        <h4>Cloud Migration</h4>
                                        <p>Transition your website or application to AWS with minimal disruption.</p>
                                    </li>
                                    <li>
                                        <h4>Custom Hosting Solutions</h4>
                                        <p>Tailored hosting environments optimized for your specific needs.</p>
                                    </li>
                                    <li>
                                        <h4>24/7 Monitoring and Support</h4>
                                        <p>Our team ensures your hosting runs smoothly, with round-the-clock monitoring and assistance.</p>
                                    </li>
                                    <li>
                                        <h4>Cost Optimization</h4>
                                        <p>We help you get the most out of your AWS investment by managing resources efficiently.</p>
                                    </li>

                                </ul>
                                <Link to='/contact' className='common_btn'>
                                    Contact Us <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </ReactLenis>
    )
}
