import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Footer from '../component/Footer';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function UIUXDesign() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <ReactLenis
            root
            options={{ gestureOrientataion: "both" }}
        >

            <Helmet>
                <title>UI/UX Design Services - MakeMyWebsite.Live | Professional Web & App Design</title>

                <meta name="description"
                    content="Explore expert UI/UX design services at MakeMyWebsite.Live. We specialize in creating intuitive, user-centered designs that enhance user experiences. Our team crafts beautiful, responsive, and functional web and mobile app designs using the latest design trends and technologies. Contact us today to elevate your digital products with custom UI/UX design solutions." />

                <meta name="keywords"
                    content="UI/UX design services India, professional UI design company, UX design company India, responsive web design, mobile app design, user interface design, user experience design India, best UI/UX designers, UI/UX design for mobile apps, website UI/UX design, custom web design India" />

                <meta name="author" content="Devsol" />
                <link rel="icon" href="https://www.makemywebsite.live/logo.ico" />
                <meta name="theme-color" content="#000000" />

                <meta property="og:site_name" content="Make My Website" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="UI/UX Design Services - MakeMyWebsite.Live" />
                <meta property="og:description"
                    content="Transform your digital presence with top-notch UI/UX design services from MakeMyWebsite.Live. Our team delivers beautiful and functional designs that enhance user interaction and engagement. We specialize in web and mobile app designs that align with your business goals." />
                <meta property="og:url" content="https://www.makemywebsite.live/ui-ux-design" />
                <meta property="og:image" content="https://www.makemywebsite.live/logo.png" />
                <meta property="og:image:alt" content="UI/UX Design Services at MakeMyWebsite" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="1200" />
                <meta property="og:location" content="Worldwide" />
                <meta property="og:locale" content="en_GB" />

                <meta property="twitter:site" content="makemywebsite" />
                <meta property="twitter:creator" content="devsol" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="UI/UX Design Services - MakeMyWebsite.Live" />
                <meta name="twitter:description"
                    content="MakeMyWebsite.Live offers exceptional UI/UX design services to help create visually appealing and user-friendly digital products. Get in touch with us for custom web and mobile app designs that prioritize user experience and engagement." />
                <meta name="twitter:image" content="https://www.makemywebsite.live/logo.png" />
                <meta name="twitter:image:src" content="https://www.makemywebsite.live/logo.png" />
                <meta property="twitter:image:width" content="1200" />
                <meta property="twitter:image:height" content="1200" />

                <meta name="referrer" content="origin-when-crossorigin" id="meta_referrer" />
            </Helmet>

            <section className='inner-banner-section p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='left-section'>
                                <h1>UI/UX Design</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item"><Link to="/">Services</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item active" aria-current="page">UI/UX Design</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='service-detail-sectiom-inner top bottom p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='banner-img-box'>
                                <img src={require('../assets/img/service-details-img.jpg')} />
                            </div>
                            <div className='detail-service-page'>
                                <h2>Crafting User-Centric Designs for Seamless Experiences</h2>
                                <p>At Makemywebsite, we specialize in creating exceptional UI/UX designs that captivate users and enhance engagement. Our design philosophy is rooted in understanding your audience's needs and delivering intuitive, aesthetically pleasing interfaces that drive results.</p>
                                <h3>Why Choose Makemywebsite for UI/UX Design?</h3>
                                <ul>
                                    <li><strong>User-Centric Approach:</strong> We prioritize the end-user experience, ensuring your product is easy to navigate and enjoyable to use.</li>
                                    <li><strong>Innovative Designs:</strong> Our team stays updated with the latest design trends to create modern and impactful interfaces.</li>
                                    <li><strong>Customized Solutions:</strong> We tailor our designs to align with your brand identity and business goals.</li>
                                    <li><strong>Seamless Functionality:</strong> Combining aesthetics with functionality, we ensure every design element serves a purpose.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='about-section-inner-1 p-l-r top bottom'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-6'>
                            <div className='right-section'>
                                <div className='img_left_d'>
                                    <img src={require('../assets/img/UI-UX Design.jpg')} />
                                </div>
                                <div className='shap_box'>
                                    <img src={require('../assets/img/shape-16.png')} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='left-section'>
                                <h4 className='sub-title'>Our UI/UX Design Process</h4>
                                <h3 className='heading'>
                                    What We Do The Best
                                </h3>
                                <ul>
                                    <li>
                                        <h4>Research & Strategy</h4>
                                        <p>We begin by understanding your business, target audience, and competitors to lay a strong foundation.</p>
                                    </li>
                                    <li>
                                        <h4>Wireframing & Prototyping</h4>
                                        <p>Our designers craft wireframes and prototypes to visualize the structure and functionality of your product.</p>
                                    </li>
                                    <li>
                                        <h4>Visual Design</h4>
                                        <p>We bring your vision to life with captivating visuals, colors, and typography that reflect your brand.</p>
                                    </li>
                                    <li>
                                        <h4>Testing & Feedback</h4>
                                        <p>Rigorous testing ensures a flawless user experience before the final launch.</p>
                                    </li>
                                    <li>
                                        <h4>Launch & Support</h4>
                                        <p>We deliver the final product with continuous support to meet evolving user needs.</p>
                                    </li>

                                </ul>
                                <Link to='/contact' className='common_btn'>
                                    Contact Us <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </ReactLenis>
    )
}
