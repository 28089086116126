import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Footer from '../component/Footer';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function DigitalMarketing() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <ReactLenis
            root
            options={{ gestureOrientataion: "both" }}
        >
            <Helmet>
                <title>Digital Marketing Services - MakeMyWebsite.Live | Web & App Development in India</title>

                <meta name="description"
                    content="Boost your online presence with MakeMyWebsite.Live's expert digital marketing services. We specialize in SEO, social media marketing, pay-per-click (PPC), content marketing, and more to help your business grow. Get tailored digital strategies to increase visibility, engage your audience, and drive conversions. Contact us for affordable digital marketing solutions in Gurugram, Haryana, India." />

                <meta name="keywords"
                    content="digital marketing services, SEO services India, social media marketing India, PPC services India, content marketing, web marketing company, online marketing India, digital marketing company Gurugram, affordable digital marketing solutions, growth marketing India, website promotion India" />

                <meta name="author" content="Devsol" />
                <link rel="icon" href="https://www.makemywebsite.live/logo.ico" />
                <meta name="theme-color" content="#000000" />

                <meta property="og:site_name" content="Make My Website" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Digital Marketing Services - MakeMyWebsite.Live" />
                <meta property="og:description"
                    content="Enhance your business visibility with MakeMyWebsite.Live's expert digital marketing services, including SEO, social media marketing, PPC, and content marketing. Tailored strategies to improve your online presence and drive measurable results." />
                <meta property="og:url" content="https://www.makemywebsite.live/digital-marketing" />
                <meta property="og:image" content="https://www.makemywebsite.live/logo.png" />
                <meta property="og:image:alt" content="Digital Marketing Services" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="1200" />
                <meta property="og:location" content="Worldwide" />
                <meta property="og:locale" content="en_GB" />

                <meta property="twitter:site" content="makemywebsite" />
                <meta property="twitter:creator" content="devsol" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Digital Marketing Services - MakeMyWebsite.Live" />
                <meta name="twitter:description"
                    content="Get professional digital marketing services with MakeMyWebsite.Live. From SEO and PPC to social media marketing and content strategies, we help you achieve your business goals with impactful digital campaigns." />
                <meta name="twitter:image" content="https://www.makemywebsite.live/logo.png" />
                <meta name="twitter:image:src" content="https://www.makemywebsite.live/logo.png" />
                <meta property="twitter:image:width" content="1200" />
                <meta property="twitter:image:height" content="1200" />

                <meta name="referrer" content="origin-when-crossorigin" id="meta_referrer" />
            </Helmet>

            <section className='inner-banner-section p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='left-section'>
                                <h1>Digital Marketing</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item"><Link to="/">Services</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item active" aria-current="page">Digital Marketing</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='service-detail-sectiom-inner top bottom p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='banner-img-box'>
                                <img src={require('../assets/img/service-details-img.jpg')} />
                            </div>
                            <div className='detail-service-page'>
                                <h2>Empowering Your Brand in the Digital Era</h2>
                                <p>At Makemywebsite, we specialize in providing cutting-edge digital marketing solutions to help your business thrive online. In today's fast-paced digital landscape, having a strong online presence is no longer optional—it’s essential. Our expert team is dedicated to crafting strategies that deliver measurable results and drive sustainable growth for your brand.</p>
                                <h3>Why Choose Makemywebsite for Digital Marketing?</h3>
                                <ul>
                                    <li><strong>Customized Strategies:</strong> We understand that every business is unique. That’s why we tailor our digital marketing solutions to meet your specific goals and challenges.</li>
                                    <li><strong>Data-Driven Results:</strong> Our strategies are backed by analytics, ensuring your marketing investments deliver maximum ROI.</li>
                                    <li><strong>End-to-End Expertise:</strong> From search engine optimization to social media management, we provide comprehensive services to cover all your digital needs.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='about-section-inner-1 p-l-r top bottom'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-6'>
                            <div className='right-section'>
                                <div className='img_left_d'>
                                    <img src={require('../assets/img/Digital Marketing.jpg')} />
                                </div>
                                <div className='shap_box'>
                                    <img src={require('../assets/img/shape-16.png')} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='left-section'>
                                <h4 className='sub-title'>Our Digital Marketing Services</h4>
                                <h3 className='heading'>
                                    What We Do The Best
                                </h3>
                                <ul>
                                    <li>
                                        <h4>Search Engine Optimization (SEO)</h4>
                                        <p>Be found where it matters most. Our SEO experts optimize your website to improve its visibility on search engines, driving organic traffic and boosting your rankings.</p>
                                    </li>
                                    <li>
                                        <h4>Pay-Per-Click (PPC) Advertising</h4>
                                        <p>Reach your audience instantly with targeted PPC campaigns. We ensure your ads are seen by the right people at the right time for optimal conversions.</p>
                                    </li>
                                    <li>
                                        <h4>Social Media Marketing</h4>
                                        <p>Engage, connect, and grow your audience on platforms like Facebook, Instagram, LinkedIn, and more. We craft compelling content and campaigns that resonate with your followers.</p>
                                    </li>
                                    <li>
                                        <h4>Content Marketing</h4>
                                        <p>Content is king, and we make it work for you. Our team creates high-quality blogs, videos, and graphics that captivate your audience and establish your brand authority.</p>
                                    </li>
                                    <li>
                                        <h4>Online Reputation Management</h4>
                                        <p>Your reputation is your brand's identity. We monitor, manage, and enhance your online presence to ensure your customers see the best of you.</p>
                                    </li>
                                </ul>
                                <Link to='/contact' className='common_btn'>
                                    Contact Us <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </ReactLenis>
    )
}
