import { useGSAP } from '@gsap/react'
import React, { useRef, useState } from 'react'
import gsap from 'gsap';
import { Link } from 'react-router-dom';
export default function Header() {

    const [toggleMenu ,setToggleMenu] = useState(false)
    const header = useRef();
   

    useGSAP(()=>{
        let tl = gsap.timeline()
        tl.to('.logo', { 'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', opacity:1, duration:1,delay:0.5, autoAlpha:1})
    },
    { scope: header }
    )

    return (
        <header ref={header} className='padding-left-right'>
            <div className='header-section'>
                <nav class="navbar navbar-expand-lg bg-body-tertiary">
                    <div class="container-fluid">
                        <Link class="navbar-brand" to="/">
                            <img className='logo' src={require('../assets/img/logo.png')} />
                        </Link>
                        {/* <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button> */}
                         <div className={`toggle-button navbar-toggler ${toggleMenu ? 'active':''}`} onClick={()=>setToggleMenu(!toggleMenu)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        <div class={`collapse navbar-collapse ${toggleMenu ? 'active':''}`} id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={()=>setToggleMenu(false)} aria-current="page" to="/about">About</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={()=>setToggleMenu(false)} to="/services">Services</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={()=>setToggleMenu(false)} to="/work">Work</Link>
                                </li>
                                {/* <li class="nav-item">
                                    <a class="nav-link" onClick={()=>setToggleMenu(false)} href="#">Blog</a>
                                </li> */}
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={()=>setToggleMenu(false)} to="/contact">Get In Touch</Link>
                                </li>
                                
                            </ul>
                           
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}
