import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

export default function About() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);


    return (
        <ReactLenis
            root
            options={{ gestureOrientataion: "both" }}
        >

            <Helmet>
                <title>About Us - MakeMyWebsite.Live | Leading Web and App Development Company</title>
                <meta name="description" content="Learn more about Makemywebsite.Live, a premier web and app development company in India. We specialize in custom website design, mobile apps, and responsive development using cutting-edge technologies like React.js, Node.js, MongoDB, and AWS." />
                <meta name="keywords" content="about Makemywebsite, web development company, app development company, custom websites, responsive web design, mobile apps India, React.js, Node.js, AWS development" />

                <meta name="author" content="Makemywebsite" />
                <link rel="icon" href="https://www.makemywebsite.live/logo.ico" />
                <link rel="apple-touch-icon" href="https://www.makemywebsite.live/logo.ico" />
                <link rel="manifest" href="https://www.makemywebsite.live/manifest.json" />


                <meta property="og:site_name" content="Makemywebsite.Live" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="About Us | MakeMyWebsite.Live - Web & App Development Services" />
                <meta property="og:description" content="Discover Makemywebsite.Live, your trusted partner in web and app development. Our team creates user-friendly, professional websites and apps using the latest technologies to boost your online presence." />
                <meta property="og:url" content="https://www.makemywebsite.live/about" />
                <meta property="og:image" content="https://www.makemywebsite.live/logo.png" />
                <meta property="og:image:alt" content="Makemywebsite Logo" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="1200" />


                <meta property="twitter:site" content="@makemywebsite" />
                <meta property="twitter:creator" content="@devsol" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="About Us - Makemywebsite.Live | Web & App Development Experts" />
                <meta name="twitter:description" content="Learn about Makemywebsite.Live, the leading web and app development company in India. We offer bespoke website design and development services powered by modern technologies." />
                <meta name="twitter:image" content="https://www.makemywebsite.live/logo.png" />
                <meta property="twitter:image:src" content="https://www.makemywebsite.live/logo.png" />
                <meta property="twitter:image:width" content="1200" />
                <meta property="twitter:image:height" content="1200" />


                <meta name="referrer" content="origin-when-crossorigin" id="meta_referrer" />
                <link rel="alternate" media="only screen and (max-width: 640px)" href="https://www.makemywebsite.live/about" />
                <link rel="alternate" media="handheld" href="https://www.makemywebsite.live/about" />

            </Helmet>

            <section className='inner-banner-section p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='left-section'>
                                <h1>About Us</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='about-section-inner p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='left-section'>
                                <h4 className='sub-title'>About Company</h4>
                                <h3 className='heading'>
                                    About Makemywebsite
                                </h3>
                                <p className='paragraph'>
                                    Welcome to Makemywebsite, where creativity meets technology to deliver stunning digital experiences!
                                </p>
                                <p>At Makemywebsite, we are passionate about helping businesses establish a powerful online presence. Whether you’re launching a new startup or looking to refresh your existing digital identity, our team of skilled professionals is dedicated to turning your vision into reality.</p>
                                <Link to='/contact' className='common_btn'>
                                    Contact Us <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='right-section'>
                                <img src={require('../assets/img/about1.jpg')} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="our-work">
                <div class="our-work__wrapper">
                    <div class="our-work__content">
                        <div class="single-work-item">
                            <span class="title">Design</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">Development</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">Marketing</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">SEO</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">Maintenance</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">Responsive</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">Development</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">Marketing</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">SEO</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">POD</span>
                            <span class="start">*</span>
                        </div>

                        <div class="single-work-item">
                            <span class="title">Design</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">Development</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">Marketing</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">SEO</span>
                            <span class="start">*</span>
                        </div>
                        <div class="single-work-item">
                            <span class="title">POD</span>
                            <span class="start">*</span>
                        </div>
                    </div>
                </div>
            </section>
            <section className='testimonials-section bottom'>
                <div class="container-fluid">
                    <div class="row mb-70">
                        <div class="col-lg-12">
                            <div class="heading-title center">
                                <span class="sub-title text-blue1 font-man">Our Testimonials</span>
                                <h2 class="heading">Clients Say <span class="text-blue1">About Us</span></h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 relative">
                            <div class="myteshome5-wrap overflow-hiden">
                                <Swiper
                                    spaceBetween={0}
                                    centeredSlides={true}
                                    loop={true}
                                    // autoplay={{
                                    //     delay: 2500,
                                    //     disableOnInteraction: false,
                                    // }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={false}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper testimonials-slider"
                                >
                                    <SwiperSlide>
                                        <div class="testimonial-style3 style3-h5 flex-three">
                                            {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                            <div class="content">
                                                <p class="des">Makemywebsite transformed our online presence. The team’s dedication and creativity exceeded our expectations!</p>
                                                <div class="testimonial-bottom flex-two">
                                                    <div class="testimonial-top flex-three">
                                                        <i class="fa-solid fa-quote-left"></i>
                                                        <p class="name">Emily Carter, <span class="job font-man">Marketing Manager, GreenTech Solutions</span></p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="testimonial-style3 style3-h5 flex-three">
                                            {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                            <div class="content">
                                                <p class="des">Incredible service and flawless execution! My website looks amazing, and the process was seamless.</p>
                                                <div class="testimonial-bottom flex-two">
                                                    <div class="testimonial-top flex-three">
                                                        <i class="fa-solid fa-quote-left"></i>
                                                        <p class="name">John Davis, <span class="job font-man">Founder, Urban Styles Co.</span></p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="testimonial-style3 style3-h5 flex-three">
                                            {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                            <div class="content">
                                                <p class="des">Makemywebsite understood our needs perfectly. The result is a website that represents our brand beautifully.</p>
                                                <div class="testimonial-bottom flex-two">
                                                    <div class="testimonial-top flex-three">
                                                        <i class="fa-solid fa-quote-left"></i>
                                                        <p class="name">Sophia Lee, <span class="job font-man">Creative Director, Blossom Events</span></p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="testimonial-style3 style3-h5 flex-three">
                                            {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                            <div class="content">
                                                <p class="des">The team at Makemywebsite is highly professional and talented. I highly recommend their services!</p>
                                                <div class="testimonial-bottom flex-two">
                                                    <div class="testimonial-top flex-three">
                                                        <i class="fa-solid fa-quote-left"></i>
                                                        <p class="name">Rajesh Kumar, <span class="job font-man">CEO, TechSphere Innovations</span></p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="testimonial-style3 style3-h5 flex-three">
                                            {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                            <div class="content">
                                                <p class="des">Their attention to detail and quick turnaround time made the entire experience delightful.</p>
                                                <div class="testimonial-bottom flex-two">
                                                    <div class="testimonial-top flex-three">
                                                        <i class="fa-solid fa-quote-left"></i>
                                                        <p class="name">Lisa Brown, <span class="job font-man">Owner, HomeNest Interiors</span></p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="testimonial-style3 style3-h5 flex-three">
                                            {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                            <div class="content">
                                                <p class="des">Makemywebsite delivered exactly what we needed—a sleek, responsive website that drives traffic!</p>
                                                <div class="testimonial-bottom flex-two">
                                                    <div class="testimonial-top flex-three">
                                                        <i class="fa-solid fa-quote-left"></i>
                                                        <p class="name">Michael Grant, <span class="job font-man">Head of Sales, Apex Automotive</span></p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="video-section bottom">
                <div class="bg-layer"></div>
                <div class="pattern-layer-2"></div>
                <div class="auto-container">
                    <div class="content-box">
                        <div class="text-box">
                            <h2>Featured <span>Works</span>
                                <i class="fa-solid fa-arrow-up-long icon-2"></i>
                            </h2>
                        </div>
                        <div class="curve-text">
                            <div class="icon-box">
                                <Link to="/contact" class="lightbox-image" data-caption="">
                                    Contact Us
                                </Link>
                            </div>
                            <div class="shape"></div>

                        </div>
                    </div>
                </div>
            </section>
            <section className='about-section-inner-1 p-l-r top bottom'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-6'>
                            <div className='right-section'>
                                <div className='img_left_d'>
                                    <img src={require('../assets/img/about-1.jpg')} />
                                </div>
                                <div className='shap_box'>
                                    <img src={require('../assets/img/shape-16.png')} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='left-section'>
                                <h4 className='sub-title'>Why Choose Us?</h4>
                                <h3 className='heading'>
                                    What We Do The Best
                                </h3>
                                <ul>
                                    <li>
                                        <h4>Client-Centric Approach</h4>
                                        <p>We believe every client has a story, and we aim to reflect that through bespoke website designs.</p>
                                    </li>
                                    <li>
                                        <h4>Expert Team</h4>
                                        <p>Our experienced team stays up-to-date with the latest trends and technologies to ensure your website stands out.</p>
                                    </li>
                                    <li>
                                        <h4>Affordable Solutions</h4>
                                        <p>Quality doesn't have to break the bank. We offer cost-effective services without compromising on excellence.</p>
                                    </li>
                                    <li>
                                        <h4>Support You Can Count On</h4>
                                        <p>From the initial design phase to post-launch support, we’re with you every step of the way.</p>
                                    </li>

                                </ul>
                                <Link to='/contact' className='common_btn'>
                                    Contact Us <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </ReactLenis>
    )
}
