
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Footer from '../component/Footer';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function WebsiteDevelopment() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <ReactLenis
            root
            options={{ gestureOrientataion: "both" }}
        >
            <Helmet>
                <Helmet>
                    <title>Website Development - MakeMyWebsite.Live | Custom Web Design & Development in India</title>

                    <meta name="description"
                        content="Looking for professional website development services? MakeMyWebsite.Live offers custom website design, e-commerce solutions, React.js development, and more. We create responsive, user-friendly websites tailored to your business needs in Gurugram, Haryana, India. Get in touch to start your web development project today." />

                    <meta name="keywords"
                        content="website development company India, custom web development, e-commerce solutions, responsive web design, React.js development, professional website development, affordable web design, website development Gurugram, website design India, business website development" />

                    <meta name="author" content="Devsol" />
                    <link rel="icon" href="https://www.makemywebsite.live/logo.ico" />
                    <meta name="theme-color" content="#000000" />

                    <meta property="og:site_name" content="Make My Website" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Website Development - MakeMyWebsite.Live" />
                    <meta property="og:description"
                        content="MakeMyWebsite.Live offers professional website development services, from custom website design to e-commerce platforms and responsive web solutions. We specialize in React.js development and create websites that help businesses grow online. Contact us for your next web development project." />
                    <meta property="og:url" content="https://www.makemywebsite.live/website-development" />
                    <meta property="og:image" content="https://www.makemywebsite.live/logo.png" />
                    <meta property="og:image:alt" content="Make My Website Website Development" />
                    <meta property="og:image:type" content="image/png" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="1200" />
                    <meta property="og:location" content="Worldwide" />
                    <meta property="og:locale" content="en_GB" />

                    <meta property="twitter:site" content="makemywebsite" />
                    <meta property="twitter:creator" content="devsol" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Website Development - MakeMyWebsite.Live" />
                    <meta name="twitter:description"
                        content="Professional website development by MakeMyWebsite.Live. Offering custom web design, e-commerce solutions, React.js development, and more to help businesses grow online. Reach out for affordable and responsive website development services." />
                    <meta name="twitter:image" content="https://www.makemywebsite.live/logo.png" />
                    <meta name="twitter:image:src" content="https://www.makemywebsite.live/logo.png" />
                    <meta property="twitter:image:width" content="1200" />
                    <meta property="twitter:image:height" content="1200" />

                    <meta name="referrer" content="origin-when-crossorigin" id="meta_referrer" />
                </Helmet>

            </Helmet>
            <section className='inner-banner-section p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='left-section'>
                                <h1>Website Development</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item"><Link to="/">Services</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item active" aria-current="page">Website Development</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='service-detail-sectiom-inner top bottom p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='banner-img-box'>
                                <img src={require('../assets/img/service-details-img.jpg')} />
                            </div>
                            <div className='detail-service-page'>
                                <h2>Your Gateway to a Powerful Online Presence</h2>
                                <p>In today's digital-first world, your website is the cornerstone of your brand. It’s more than just an online presence; it’s your most effective marketing tool and your virtual storefront. At Makemywebsite, we specialize in building high-performing, responsive, and visually stunning websites that help your business grow.</p>
                                <h3>What We Offer</h3>
                                <h4>Custom Web Development</h4>
                                <p>Tailored solutions that align with your brand identity and business goals. We craft unique websites that stand out from the competition.</p>
                                <h4>Responsive Design</h4>
                                <p>Your website will look great and perform seamlessly on all devices – from desktops to smartphones.</p>
                                <h4>E-commerce Solutions</h4>
                                <p>Launch your online store with robust and secure e-commerce functionality. From product pages to payment gateways, we’ve got you covered.</p>
                                <h4>Content Management Systems (CMS)</h4>
                                <p>Easily manage your website’s content with intuitive CMS platforms like WordPress, Drupal, or custom solutions.</p>
                                <h4>Search Engine Optimization (SEO)</h4>
                                <p>We build websites that are not only beautiful but also optimized to rank higher on search engines, bringing you organic traffic.</p>
                                <h4>Web Maintenance and Support</h4>
                                <p>Our job doesn’t end with development. We offer ongoing maintenance to ensure your website runs smoothly 24/7.</p>

                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='about-section-inner-1 p-l-r top bottom'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-6'>
                            <div className='right-section'>
                                <div className='img_left_d'>
                                    <img src={require('../assets/img/Website Development.jpg')} />
                                </div>
                                <div className='shap_box'>
                                    <img src={require('../assets/img/shape-16.png')} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='left-section'>
                                <h4 className='sub-title'>Why Choose Makemywebsite?</h4>
                                <h3 className='heading'>
                                    What We Do The Best
                                </h3>
                                <ul>
                                    <li>
                                        <h4>Expert Developers </h4>
                                        <p>A team of skilled professionals passionate about creating exceptional websites.</p>
                                    </li>
                                    <li>
                                        <h4>User-Centric Approach </h4>
                                        <p>We prioritize user experience to deliver a site that is intuitive and engaging.</p>
                                    </li>
                                    <li>
                                        <h4>Fast Turnaround </h4>
                                        <p>Get your website up and running quickly without compromising on quality.</p>
                                    </li>
                                    <li>
                                        <h4>Affordable Pricing</h4>
                                        <p>High-quality services that fit your budget.</p>
                                    </li>
                                    <li>
                                        <h4>Scalable Solutions</h4>
                                        <p>Websites built to grow with your business.</p>
                                    </li>
                                </ul>
                                <Link to='/contact' className='common_btn'>
                                    Contact Us <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </ReactLenis>
    )
}
