import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Footer from '../component/Footer';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function Services() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <ReactLenis
            root
            options={{ gestureOrientataion: "both" }}
        >
            <Helmet>
                <title>Services - MakeMyWebsite.Live | Web & App Development Company in India</title>

                <meta name="description"
                    content="Explore MakeMyWebsite.Live's top-tier services in web development, mobile app development, React.js, and e-commerce solutions. We provide responsive, user-friendly websites tailored to your business needs. From custom web design to mobile apps and bespoke development solutions, get started with affordable, high-quality services in Gurugram, Haryana, India." />

                <meta name="keywords"
                    content="web development services, mobile app development India, React.js development, e-commerce solutions, custom website design, responsive websites, business website development, website redesign, mobile app development, affordable web development India, top web development companies India, MakeMyWebsite services, digital solutions India" />

                <meta name="author" content="Devsol" />
                <link rel="icon" href="https://www.makemywebsite.live/logo.ico" />
                <meta name="theme-color" content="#000000" />

                <meta property="og:site_name" content="Make My Website" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Services - MakeMyWebsite.Live" />
                <meta property="og:description"
                    content="At MakeMyWebsite.Live, we offer a range of services including custom web development, mobile apps, React.js solutions, e-commerce website development, and more. Our tailored services help businesses create an online presence that is both functional and user-friendly. Get in touch to start your project today." />
                <meta property="og:url" content="https://www.makemywebsite.live/services" />
                <meta property="og:image" content="https://www.makemywebsite.live/logo.png" />
                <meta property="og:image:alt" content="Make My Website Services Page" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="1200" />
                <meta property="og:location" content="Worldwide" />
                <meta property="og:locale" content="en_GB" />

                <meta property="twitter:site" content="makemywebsite" />
                <meta property="twitter:creator" content="devsol" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Services - MakeMyWebsite.Live" />
                <meta name="twitter:description"
                    content="Discover the full range of services offered by MakeMyWebsite.Live. From custom website design to mobile apps and e-commerce platforms, we specialize in delivering user-centric digital solutions for businesses in India and beyond." />
                <meta name="twitter:image" content="https://www.makemywebsite.live/logo.png" />
                <meta name="twitter:image:src" content="https://www.makemywebsite.live/logo.png" />
                <meta property="twitter:image:width" content="1200" />
                <meta property="twitter:image:height" content="1200" />

                <meta name="referrer" content="origin-when-crossorigin" id="meta_referrer" />
            </Helmet>

            <section className='inner-banner-section p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='left-section'>
                                <h1>Services</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item active" aria-current="page">Services</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="services-section top bottom p-l-r">
                <div class="container-fluid">
                    <div className='header-title'>
                        <h4 className='sub-title text-left'>Services</h4>
                        <h3 className='heading  text-left'>
                            Most <em>experienced</em><br />services
                        </h3>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-4 col-md-6">
                            <div class="service-box">

                                <div class="left-section">
                                    <div class="icon-box">
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: require("../assets/lotti/web.json"),
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice"
                                                }
                                            }}
                                            height={'20%'}
                                            width={'40%'}
                                        />
                                    </div>
                                    <div>
                                        <div class="service-detail">
                                            <span>.01</span>
                                            <h4 data-name="Website Development">Website Development</h4>
                                        </div>
                                        <div class="service-paragraph">
                                            <p class="paragraph1">Web & Mobile Sites User Interface Design Responsive Techs.</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="right-section">
                                    <Link to="/website-development">
                                        <img src={require('../assets/img/Website Development.jpg')} alt="" />
                                    </Link>
                                </div>
                                <Link to='/website-development' className='common_btn'>
                                    Read More <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="service-box">

                                <div class="left-section">
                                    <div class="icon-box">
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: require("../assets/lotti/app.json"),
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice"
                                                }
                                            }}
                                            height={'20%'}
                                            width={'40%'}
                                        />
                                    </div>
                                    <div>
                                        <div class="service-detail">
                                            <span>.01</span>
                                            <h4 data-name="Mobile App Development">Mobile App Development</h4>
                                        </div>
                                        <div class="service-paragraph">
                                            <p class="paragraph1">Get handy solutions to bring your project to life.</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="right-section">
                                    <Link to="/mobile-app-development">
                                        <img src={require('../assets/img/Mobile App Development.jpg')} alt="" />
                                    </Link>
                                </div>
                                <Link to='/mobile-app-development' className='common_btn'>
                                    Read More <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="service-box">

                                <div class="left-section">
                                    <div class="icon-box">
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: require("../assets/lotti/server.json"),
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice"
                                                }
                                            }}
                                            height={'20%'}
                                            width={'40%'}
                                        />
                                    </div>
                                    <div>
                                        <div class="service-detail">
                                            <span>.01</span>
                                            <h4 data-name="AWS Hosting">AWS Hosting</h4>
                                        </div>
                                        <div class="service-paragraph">
                                            <p class="paragraph1">Live your project to the scalable environment.</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="right-section">
                                    <Link to="/aws-hosting">
                                        <img src={require('../assets/img/AWS Hosting.jpg')} alt="" />
                                    </Link>
                                </div>
                                <Link to='/aws-hosting' className='common_btn'>
                                    Read More <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="service-box">

                                <div class="left-section">
                                    <div class="icon-box">
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: require("../assets/lotti/ui.json"),
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice"
                                                }
                                            }}
                                            height={'20%'}
                                            width={'40%'}
                                        />
                                    </div>
                                    <div>
                                        <div class="service-detail">
                                            <span>.01</span>
                                            <h4 data-name="AWS Hosting">UI/UX Design</h4>
                                        </div>
                                        <div class="service-paragraph">
                                            <p class="paragraph1">Get your brand's sight even before coding.</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="right-section">
                                    <Link to="/ui-ux-design">
                                        <img src={require('../assets/img/UI-UX Design.jpg')} alt="" />
                                    </Link>
                                </div>
                                <Link to='/ui-ux-design' className='common_btn'>
                                    Read More <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="service-box">

                                <div class="left-section">
                                    <div class="icon-box">
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: require("../assets/lotti/digital.json"),
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice"
                                                }
                                            }}
                                            height={'20%'}
                                            width={'40%'}
                                        />
                                    </div>
                                    <div>
                                        <div class="service-detail">
                                            <span>.01</span>
                                            <h4 data-name="Digital Marketing">Digital Marketing</h4>
                                        </div>
                                        <div class="service-paragraph">
                                            <p class="paragraph1">Promote and get ranks for your brand to the higher level.</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="right-section">
                                    <Link to="/digital-marketing">
                                        <img src={require('../assets/img/Digital Marketing.jpg')} alt="" />
                                    </Link>
                                </div>
                                <Link to='/digital-marketing' className='common_btn'>
                                    Read More <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="service-box">

                                <div class="left-section">
                                    <div class="icon-box">
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: require("../assets/lotti/graphic.json"),
                                                rendererSettings: {
                                                    preserveAspectRatio: "xMidYMid slice"
                                                }
                                            }}
                                            height={'20%'}
                                            width={'40%'}
                                        />
                                    </div>
                                    <div>
                                        <div class="service-detail">
                                            <span>.01</span>
                                            <h4 data-name="Graphic Designing">Graphic Designing</h4>
                                        </div>
                                        <div class="service-paragraph">
                                            <p class="paragraph1">Cool and creative Logo, Images, Social media posts.</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="right-section">
                                    <Link to="/graphic-designing">
                                        <img src={require('../assets/img/Graphic Designing.jpg')} alt="" />
                                    </Link>
                                </div>
                                <Link to='/graphic-designing' className='common_btn'>
                                    Read More <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='about-section-inner-1 p-l-r bottom'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-6'>
                            <div className='right-section'>
                                <div className='img_left_d'>
                                    <img src={require('../assets/img/service-1.jpg')} />
                                </div>
                                <div className='shap_box'>
                                    <img src={require('../assets/img/shape-16.png')} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='left-section'>
                                <h4 className='sub-title'>Make Our Best Service</h4>
                                <h3 className='heading'>
                                    What We Do The Best
                                </h3>
                                <ul>
                                    <li>
                                        <h4>Level Up In Your Business </h4>
                                        <p>Pellentesque sit amet urna justo. Fusce velit nibh commodo vestibulum condimentu.</p>
                                    </li>
                                    <li>
                                        <h4>Level Up In Your Business </h4>
                                        <p>Pellentesque sit amet urna justo. Fusce velit nibh commodo vestibulum condimentu.</p>
                                    </li>
                                    <li>
                                        <h4>Level Up In Your Business </h4>
                                        <p>Pellentesque sit amet urna justo. Fusce velit nibh commodo vestibulum condimentu.</p>
                                    </li>
                                </ul>
                                <Link to='/contact' className='common_btn'>
                                    Contact Us <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </ReactLenis>
    )
}
