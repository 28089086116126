import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Header from '../component/Header'
import Footer from '../component/Footer'
import About from '../pages/About'
import Work from '../pages/Work'
import Services from '../pages/Services'
import WebsiteDevelopment from '../pages/WebsiteDevelopment'
import MobileAppDevelopment from '../pages/MobileAppDevelopment'
import AWSHosting from '../pages/AWSHosting'
import UIUXDesign from '../pages/UIUXDesign'
import DigitalMarketing from '../pages/DigitalMarketing'
import GraphicDesigning from '../pages/GraphicDesigning'
import Contact from '../pages/Contact'

export default function Router() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route index element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/work' element={<Work />} />
                <Route path='/services' element={<Services />} />
                <Route path='/website-development' element={<WebsiteDevelopment />} />
                <Route path='/mobile-app-development' element={<MobileAppDevelopment />} />
                <Route path='/ui-ux-design' element={<UIUXDesign />} />
                <Route path='/aws-hosting' element={<AWSHosting />} />
                <Route path='/digital-marketing' element={<DigitalMarketing />} />
                <Route path='/graphic-designing' element={<GraphicDesigning />} />
                <Route path='/contact' element={<Contact />} />
                {/* <Route path="*" element={<NoPage />} /> */}
            </Routes>
        </BrowserRouter>
    )
}
