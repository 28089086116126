import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Footer from '../component/Footer';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function GraphicDesigning() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <ReactLenis
            root
            options={{ gestureOrientataion: "both" }}
        >
            <Helmet>
                <title>Graphic Designing - MakeMyWebsite.Live | Creative Design Solutions in India</title>

                <meta name="description"
                    content="Discover top-notch graphic design services at MakeMyWebsite.Live. Our team specializes in creating stunning logos, brand identities, marketing materials, and more. Whether you're looking to revamp your brand or create eye-catching visuals, we deliver creative, professional designs tailored to your business needs." />

                <meta name="keywords"
                    content="graphic designing India, professional graphic design services, logo design, branding design, marketing materials design, business cards design, graphic design company, custom graphic design India, creative graphic design agency" />

                <meta name="author" content="Devsol" />
                <link rel="icon" href="https://www.makemywebsite.live/logo.ico" />
                <meta name="theme-color" content="#000000" />

                <meta property="og:site_name" content="Make My Website" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Graphic Designing - MakeMyWebsite.Live" />
                <meta property="og:description"
                    content="Explore our graphic design services at MakeMyWebsite.Live. Our expert team specializes in creating unique, professional graphics such as logos, brochures, and marketing materials that effectively communicate your brand’s message." />
                <meta property="og:url" content="https://www.makemywebsite.live/graphic-designing" />
                <meta property="og:image" content="https://www.makemywebsite.live/logo.png" />
                <meta property="og:image:alt" content="Graphic Designing Page" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="1200" />
                <meta property="og:location" content="Worldwide" />
                <meta property="og:locale" content="en_GB" />

                <meta property="twitter:site" content="makemywebsite" />
                <meta property="twitter:creator" content="devsol" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Graphic Designing - MakeMyWebsite.Live" />
                <meta name="twitter:description"
                    content="Get professional graphic design services from MakeMyWebsite.Live. Our team creates stunning visuals including logos, branding, and marketing materials that make your brand stand out." />
                <meta name="twitter:image" content="https://www.makemywebsite.live/logo.png" />
                <meta name="twitter:image:src" content="https://www.makemywebsite.live/logo.png" />
                <meta property="twitter:image:width" content="1200" />
                <meta property="twitter:image:height" content="1200" />

                <meta name="referrer" content="origin-when-crossorigin" id="meta_referrer" />
            </Helmet>

            <section className='inner-banner-section p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='left-section'>
                                <h1>Graphic Designing</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item"><Link to="/">Services</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item active" aria-current="page">Graphic Designing</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='service-detail-sectiom-inner top bottom p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='banner-img-box'>
                                <img src={require('../assets/img/service-details-img.jpg')} />
                            </div>
                            <div className='detail-service-page'>
                                <h2>Graphic Designing Services - Makemywebsite</h2>
                                <p>At Makemywebsite, we believe that exceptional graphic design is the cornerstone of a strong brand identity. Our creative team crafts visually stunning designs that not only captivate but also communicate your brand's message effectively.</p>
                                <h3>Why Choose Makemywebsite for Graphic Design?</h3>
                                <ul>
                                    <li><strong>Tailored Designs:</strong> We create custom graphics that align with your brand's vision and goals.</li>
                                    <li><strong>Creative Expertise:</strong> Our skilled designers bring innovative ideas to life, ensuring your brand stands out.</li>
                                    <li><strong>High-Quality Outputs:</strong> From pixel-perfect designs to print-ready materials, we ensure top-notch quality in every project.</li>
                                    <li><strong>Timely Delivery:</strong> We understand the importance of deadlines and deliver exceptional results on time.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='about-section-inner-1 p-l-r top bottom'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-6'>
                            <div className='right-section'>
                                <div className='img_left_d'>
                                    <img src={require('../assets/img/Graphic Designing.jpg')} />
                                </div>
                                <div className='shap_box'>
                                    <img src={require('../assets/img/shape-16.png')} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='left-section'>
                                <h4 className='sub-title'>Our Graphic Design Services</h4>
                                <h3 className='heading'>
                                    What We Do The Best
                                </h3>
                                <ul>
                                    <li>
                                        <h4>Logo Design</h4>
                                        <p>Build a memorable brand identity with a unique and impactful logo</p>
                                    </li>
                                    <li>
                                        <h4>Business Cards & Stationery</h4>
                                        <p>Leave a lasting impression with professionally designed business essentials.</p>
                                    </li>
                                    <li>
                                        <h4>Social Media Graphics</h4>
                                        <p>Boost your online presence with eye-catching posts, banners, and ads.</p>
                                    </li>
                                    <li>
                                        <h4>Brochures & Flyers</h4>
                                        <p>Communicate your offerings effectively through visually appealing marketing materials.</p>
                                    </li>
                                    <li>
                                        <h4>Website Graphics</h4>
                                        <p>Enhance user experience with custom graphics and UI elements for your website.</p>
                                    </li>
                                    <li>
                                        <h4>Packaging Design</h4>
                                        <p>Create packaging that speaks volumes about your brand and product.</p>
                                    </li>

                                </ul>
                                <Link to='/contact' className='common_btn'>
                                    Contact Us <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </ReactLenis>
    )
}
