import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function Contact() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <ReactLenis
            root
            options={{ gestureOrientataion: "both" }}
        >
            <Helmet >
                <title>Contact Us - MakeMyWebsite.Live | Web & App Development in India</title>

                <meta name="description"
                    content="Get in touch with MakeMyWebsite.Live, a leading web development and app development company in India. Contact us for customized website design, e-commerce solutions, React.js development, mobile apps, and more. We are here to help you create your ideal online presence. Reach out now for affordable web and app development services in Gurugram, Haryana, India."/>

                    <meta name="keywords"
                        content="contact web development company India, contact app development company India, website design contact, contact MakeMyWebsite, web development contact form, affordable web development India, React.js development company, mobile app development India, e-commerce solutions India, contact website development company, web development Gurugram" />

                    <meta name="author" content="Devsol" />
                    <link rel="icon" href="https://www.makemywebsite.live/logo.ico" />
                    <meta name="theme-color" content="#000000" />

                    <meta property="og:site_name" content="Make My Website" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Contact Us - MakeMyWebsite.Live" />
                    <meta property="og:description"
                        content="Contact MakeMyWebsite.Live for all your web development and mobile app needs. From custom website design to e-commerce platforms and mobile apps, our team is here to help you with responsive and user-friendly solutions. Reach out now to discuss your project." />
                    <meta property="og:url" content="https://www.makemywebsite.live/contact" />
                    <meta property="og:image" content="https://www.makemywebsite.live/logo.png" />
                    <meta property="og:image:alt" content="Make My Website Contact Page" />
                    <meta property="og:image:type" content="image/png" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="1200" />
                    <meta property="og:location" content="Worldwide" />
                    <meta property="og:locale" content="en_GB" />

                    <meta property="twitter:site" content="makemywebsite" />
                    <meta property="twitter:creator" content="devsol" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Contact Us - MakeMyWebsite.Live" />
                    <meta name="twitter:description"
                        content="Looking to get a website or mobile app developed? Contact MakeMyWebsite.Live for expert services in web design, React.js development, mobile app development, and more. We provide affordable and responsive digital solutions." />
                    <meta name="twitter:image" content="https://www.makemywebsite.live/logo.png" />
                    <meta name="twitter:image:src" content="https://www.makemywebsite.live/logo.png" />
                    <meta property="twitter:image:width" content="1200" />
                    <meta property="twitter:image:height" content="1200" />

                    <meta name="referrer" content="origin-when-crossorigin" id="meta_referrer" />

            </Helmet>
            <section className='inner-banner-section p-l-r'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='left-section'>
                                <h1>Get in Touch</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item active" aria-current="page">Get in Touch</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </ReactLenis>
    )
}
