import React, { useEffect, useMemo, useRef, useState } from 'react'
import Lottie from 'react-lottie';
import * as Colors from '../Utils/Color'
import animationData from '../assets/lotti/web-app.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import Lenis from '@studio-freight/lenis'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { Grid, Pagination, Navigation, Autoplay } from 'swiper/modules';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import SplitType from 'split-type'
import { Link } from 'react-router-dom';
import Loader from '../component/Loader';
import Footer from '../component/Footer';
import { Helmet } from 'react-helmet';

gsap.registerPlugin(useGSAP, ScrollTrigger);


export default function Home() {
    const [activeTab, setActiveTab] = useState('all');
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);

    const main = useRef();
    const banner = useRef();
    const about = useRef();
    const sliderRef = useRef(null);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    var tl = gsap.timeline({ repeat: 2, repeatDelay: 1 });
    tl.to(".box", { x: -600, duration: 1 }, 2);

    useGSAP(() => {
        let tl = gsap.timeline()
        tl.to('.banner-section .left-section h2', { 'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)', opacity: 1, x: 0, skewX: 0, duration: 2, delay: 1.4, autoAlpha: 1 })
        tl.from('.paragraph-b', { opacity: 0, y: window?.innerWidth < 768 ? 40 : 100, duration: window?.innerWidth < 768 ? 0.1 : 2, delay: window?.innerWidth < 768 ? 0 : 1.2, autoAlpha: 1 })
        const splitTypes1 = document.querySelectorAll('.reveal-type-3')
        splitTypes1.forEach((char, i) => {

            const bg = char.dataset.bgColor
            const fg = char.dataset.fgColor

            const text = new SplitType(char, { types: 'chars' })

            gsap.from(text.chars,
                {
                    opacity: 0,
                    stagger: 0.01,
                    delay: window?.innerWidth < 768 ? 1 : 4
                })
        })
        const ba = gsap.timeline({ scrollTrigger: { scrub: 1, trigger: ".home_page_wrap", start: window?.innerWidth < 768 ? "top 90%" : "top 0%", end: window?.innerWidth < 768 ? "bottom 50%" : "bottom 0%" } });

        ba.fromTo('.about-section', { y: window?.innerWidth < 576 ? 0 : 800, }, { y: 0, duration: 1 }, window.innerWidth < 768 ? 0.1 : 0.5);

        const splitTypes = document.querySelectorAll('.reveal-type');
        const splitTypeszoom = document.querySelectorAll('.zoom-animtion');
        const splitTypes_1 = document.querySelectorAll('.reveal-type-1');
        const splitTypes2 = document.querySelectorAll('.reveal-type-2');

        // splitTypeszoom.forEach((char, i) => {
        //     const bg = char.dataset.bgColor;
        //     const fg = char.dataset.fgColor;

        //     const text = new SplitType(char, { types: "chars" });

        //     ba.to(text.chars, {
        //         scale: 50,
        //         delay: 0.2,
        //         duration: 0.1,
        //         opacity: 1,
        //     }, window.innerWidth < 768 ? 1:3.5);
        // });

        splitTypes.forEach((char, i) => {
            const bg = char.dataset.bgColor;
            const fg = char.dataset.fgColor;

            const text = new SplitType(char, { types: "chars" });

            ba.to(text.chars, {
                x: 0,
                skewX: 0,
                stagger: 0.02,
                delay: 0.2,
                duration: 0.1,
                opacity: 1,
            }, window.innerWidth < 768 ? -0.5 : 1);
        });



        splitTypes2.forEach((char, i) => {

            const bg = char.dataset.bgColor
            const fg = char.dataset.fgColor

            const text = new SplitType(char, { types: 'chars' })

            ba.from(text.chars,
                {

                    scaleY: 0,
                    y: -30,
                    transformOrigin: 'top',
                    stagger: 0.01,
                }, window.innerWidth < 768 ? 0.2 : 1)
        })
        splitTypes_1.forEach((char, i) => {

            const bg = char.dataset.bgColor
            const fg = char.dataset.fgColor

            const text = new SplitType(char, { types: 'chars' })

            ba.from(text.chars,
                {
                    opacity: window?.innerWidth < 768 ? 1 : 0,
                    stagger: 0.01,
                }, window.innerWidth < 768 ? 0.2 : 0.5)
        })

    }
    )

    useGSAP(
        () => {
            const paths = [...document.querySelectorAll('path.path-anim')];
            paths.forEach(el => {
                const svgEl = el.closest('svg');
                const pathTo = el.dataset.pathTo;

                gsap.timeline({
                    scrollTrigger: {
                        trigger: svgEl,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: true
                    }
                })
                    .to(el, {
                        ease: 'none',
                        attr: { d: pathTo }
                    });
            });
            const section_1 = document.getElementById("vertical");
            const col_left = document.querySelector(".col_left");
            const timeln = gsap.timeline({
                paused: true
            });
            timeln.fromTo(col_left, {
                y: 0
            },
                {
                    y: '140vh',
                    duration: 1,
                    ease: 'power1.inOut'
                }, 0);
            const scroll_1 = ScrollTrigger.create({
                animation: timeln,
                trigger: section_1,
                start: 'top top',
                end: 'bottom center',
                scrub: true
            });

            const profolio_item = gsap.utils.toArray('.profolio-item');
            profolio_item.forEach((gsapItem) => {
                const img_box_main = gsapItem.querySelector('.img_box_main');
                const img_box = gsapItem.querySelector('.img_box');
                const img = gsapItem.querySelector('.img_box img');
                const h3 = gsapItem.querySelector('.profolio-item h3');
                const h5 = gsapItem.querySelector('.profolio-item h5');
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: gsapItem,
                        start: 'top 70%',
                        toggleActions: 'play none none none',
                        // markers: true
                    }
                })
                tl.from(h3, 1, { opacity: 0, translateX: '500px', ease: "expo.out" })
                tl.from(h5, 1, { opacity: 0, translateX: '500px', ease: "expo.out" })
                tl.to(img_box_main, 1, { clipPath: "polygon(0% 0%,100% 0%,100%  100%, 0% 100% )", ease: 'expo.out' }, "-=1")
                tl.to(img_box, 1.5, { clipPath: "polygon(0% 0%,100% 0%,100%  100%, 0% 100% )", ease: 'expo.out' }, "-=0.5")
                tl.from(img, 4, { scale: 1.4, filter: "blur(15px)", ease: 'expo.out' }, "-=1.7")

                let tl1 = gsap.timeline({
                    scrollTrigger: {
                        trigger: '.about-section',
                        start: 'top 70%',

                    }
                })
                tl1.from('.about-section h2', 1, { y: 50, direction: 1, ease: 'power1.out' })


            })


        },
        { scope: main }
    );

    useGSAP(() => {
        const slider = sliderRef.current;
        const box_items = slider.querySelectorAll('.horizontal__item');
        const baa = gsap.timeline({ scrollTrigger: { scrub: 1, trigger: ".second_home_section", start: "top 0%", end: "bottom 0%" } });
        const splitTypes = document.querySelectorAll('.reveal-type-4');
        splitTypes.forEach((char, i) => {
            const bg = char.dataset.bgColor;
            const fg = char.dataset.fgColor;

            const text = new SplitType(char, { types: "chars" });

            baa.to(text.chars, {
                x: 0,
                skewX: 0,
                stagger: window?.innerWidth < 768 ? 0.001 : 0.005,
                delay: 0,
                duration: 0.05,
                opacity: 1,
            }, 0.1);
        });
        baa.to(box_items, {
            xPercent: (window?.innerWidth < 768 ? -150 : -130) * (box_items.length - 1),
            ease: "power1.inOut",
            // x: () => -(section_2.scrollWidth - document.documentElement.clientWidth) + "px",
            ease: 'none'
        }, 0.3);
    }
    )

    const handleChange = (e) => {
        setValue(e.target.value)
        // if(e.target.value > 0 )
    }


    return (
        <>
            <ReactLenis
                root
                options={{ gestureOrientataion: "both" }}
            >
                <Helmet>
                    <link rel="canonical" href="https://www.makemywebsite.live" />

                    <title>MakeMyWebsite.Live - App Development, Development Company, Web Design India, Web Development Company India</title>

                    <meta name="description"
                        content="Leading website design and development company in Gurgaon, Haryana, offering professional web development and design services. We specialize in creating responsive, user-friendly websites tailored to your business needs. Get your own website or mobile app with MakeMyWebsite.Live. From travel sites to CRMs, we offer customizable templates and bespoke designs. Powered by React.js, Node.js, MongoDB, and hosted on AWS. Get a user-friendly admin panel and mobile apps developed with React Native. Choose from e-commerce solutions and responsive designs. Contact us for affordable web development packages and professional business websites." />

                    <meta name="keywords"
                        content="website development company near Gurugram, Haryana, website development company in India, website development company Delhi, website development company near me, website development services, best website development company in India, website development company Mumbai, website development company Noida, Web Development Company India, App Development Company India, Development Company India" />

                    <meta name="author" content="Devsol" />
                    <link rel="icon" href="https://www.makemywebsite.live/logo.ico" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="theme-color" content="#000000" />
                    <link rel="apple-touch-icon" href="https://www.makemywebsite.live/logo.ico" />
                    <link rel="manifest" href="https://www.makemywebsite.live/manifest.json" />

                    <meta property="og:site_name" content="Make My Website" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="MakeMyWebsite.Live - Get Your Own Website or Mobile App" />
                    <meta property="og:description"
                        content="Leading website design and development company in Gurgaon, Haryana, offering professional web development and design services. We specialize in creating responsive, user-friendly websites tailored to your business needs. Get your own website or mobile app with MakeMyWebsite.Live. From travel sites to CRMs, we offer customizable templates and bespoke designs. Powered by React.js, Node.js, MongoDB, and hosted on AWS. Get a user-friendly admin panel and mobile apps developed with React Native. Choose from e-commerce solutions and responsive designs. Contact us for affordable web development packages and professional business websites." />
                    <meta property="og:url" content="https://www.makemywebsite.live" />
                    <meta property="og:image" content="https://www.makemywebsite.live/logo.png" />
                    <meta property="og:image:alt" content="Make My Website Thumbnail" />
                    <meta property="og:image:type" content="image/png" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="1200" />
                    <meta property="og:location" content="Worldwide" />
                    <meta property="og:locale" content="en_GB" />

                    <meta property="twitter:site" content="makemywebsite" />
                    <meta property="twitter:creator" content="devsol" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="MakeMyWebsite.Live - Get Your Own Website or Mobile App" />
                    <meta name="twitter:description"
                        content="Leading website design and development company in Gurgaon, Haryana, offering professional web development and design services. We specialize in creating responsive, user-friendly websites tailored to your business needs. Get your own website or mobile app with MakeMyWebsite.Live. From travel sites to CRMs, we offer customizable templates and bespoke designs. Powered by React.js, Node.js, MongoDB, and hosted on AWS. Get a user-friendly admin panel and mobile apps developed with React Native. Choose from e-commerce solutions and responsive designs. Contact us for affordable web development packages and professional business websites." />
                    <meta name="twitter:image" content="https://www.makemywebsite.live/logo.png" />
                    <meta property="twitter:image:src" content="https://www.makemywebsite.live/logo.png" />
                    <meta property="twitter:image:width" content="1200" />
                    <meta property="twitter:image:height" content="1200" />

                    <meta name="referrer" content="origin-when-crossorigin" id="meta_referrer" />
                    <link rel="alternate" media="only screen and (max-width: 640px)" href="https://www.makemywebsite.live/" />
                    <link rel="alternate" media="handheld" href="https://www.makemywebsite.live/" />
                </Helmet>

                <Loader visible={loading} />
                <div className='home_page_wrap'>
                    <div className='section_wrapper' >
                        <section ref={banner} className='banner-section  padding-left-right'>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <div className='left-section'>
                                            <h2 className='heading '>Unlock Limitless Possibilities: MakeMyWebsite</h2>
                                            <p className='reveal-type-3 paragraph-b'>Whether you need a travel site, personal blog, CRM, or custom software, we have the templates and expertise to bring your vision to life.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-7'>
                                        <div className='right-section'>
                                            <Lottie
                                                options={{
                                                    loop: true,
                                                    autoplay: true,
                                                    animationData: animationData,
                                                    rendererSettings: {
                                                        preserveAspectRatio: "xMidYMid slice"
                                                    }
                                                }}
                                                height={'70%'}
                                                width={'90%'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <hr className='divider' /> */}
                        <section className='about-section top padding-left-right'>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='about-section-left'>
                                            <div className='abt'>
                                                <h2 class="reveal-type" data-fg-color="black" data-bg-color="#b6b600">About Us</h2>
                                                {/* <h3 class="reveal-type">Devsol</h3> */}
                                                <p className='text-white mt-3 reveal-type-1' style={{ textAlign: 'left' }}>At MakeMyWebsite.Live, we specialize in developing websites and mobile applications tailored to your specific needs. Utilizing the latest technologies like React.js, React Native, Node.js, and MongoDB, we ensure your project is both cutting-edge and reliable. Our team is dedicated to providing custom designs and scalable solutions, hosted on robust AWS servers for optimal performance.</p>
                                            </div>
                                            <Link to='mailto:makemywebsite.live@gmail.com' class="reveal-type-1">Get In Touch</Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='about-right-section'>
                                            <p class="reveal-type-1">Ready to start your project or have any questions? Contact us today to learn more about how we can help you achieve your goals.</p>
                                            <h4 class="reveal-type-1">Your Dreams</h4>
                                            <h6 class="reveal-type-1">Development Agancy</h6>
                                            <div className='experience'>
                                                <h3 class="zoom-animtion">
                                                    5 +
                                                </h3>
                                                <h2 class="reveal-type-1">
                                                    YEARS OF<br />
                                                    DIGITAL EXPERIENCE
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                </div>

                <section ref={main} className='portfolio-sect padding-left-right top bottom' id="vertical">
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <div className='heading-title col_left'>
                                    <h3 class="reveal-type-1">Portfolio</h3>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                {/* <a href='#'> */}
                                <div className='profolio-item  right_padding'>
                                    <div className='img_box_main'>
                                        <div className='img_box'>
                                            <img src={require("../assets/gif/design.gif")} />
                                        </div>
                                    </div>
                                    <h3 class="reveal-type-1">Bespoke Designs</h3>
                                    <h5 class="reveal-type-2">Get unique, tailor-made designs with spacial effects</h5>
                                </div>
                                {/* </a> */}
                                {/* <a href='#'> */}
                                <div className='profolio-item single-box right_padding'>
                                    <div className='img_box_main'>
                                        <div className='img_box'>
                                            <img src={require("../assets/gif/seo.gif")} />
                                        </div>
                                    </div>
                                    <h3 class="reveal-type-1">SEO Optimized</h3>
                                    <h5 class="reveal-type-2">Ensure your website ranks</h5>
                                </div>
                                {/* </a> */}
                                {/* <a href='#'> */}
                                <div className='profolio-item full-box right_padding'>
                                    <div className='img_box_main'>
                                        <div className='img_box'>
                                            <img src={require("../assets/gif/crm.gif")} />
                                        </div>
                                    </div>
                                    <h3 class="reveal-type-1">Custom Software Solutions</h3>
                                    <h5 class="reveal-type-2">Appointments, Bookings, Management</h5>
                                </div>
                                {/* </a> */}


                            </div>
                            <div className='col-lg-5'>
                                {/* <a href='#'> */}
                                <div className='profolio-item single-box first-box padding_left'>
                                    <div className='img_box_main'>
                                        <div className='img_box'>
                                            <img src={require("../assets/gif/fullstack.gif")} />
                                        </div>
                                    </div>
                                    <h3 class="reveal-type-1">Functionality</h3>
                                    <h5 class="reveal-type-2">Full-Stack Development</h5>
                                </div>
                                {/* </a> */}
                                {/* <a href='#'> */}
                                <div className='profolio-item full-box padding_left'>
                                    <div className='img_box_main'>
                                        <div className='img_box'>
                                            <img src={require("../assets/gif/mobile.gif")} />
                                        </div>
                                    </div>
                                    <h3 class="reveal-type-1">Mobile App Development</h3>
                                    <h5 class="reveal-type-2">App for both Android and iOS.</h5>
                                </div>
                                {/* </a> */}
                                {/* <a href='#'> */}
                                <div className='profolio-item single-box padding_left'>
                                    <div className='img_box_main'>
                                        <div className='img_box'>
                                            <img src={require("../assets/gif/dynamic.gif")} />
                                        </div>
                                    </div>
                                    <h3 class="reveal-type-1">Dynamic Content</h3>
                                    <h5 class="reveal-type-2">Admin Panel to manage the web or app contents</h5>
                                </div>
                                {/* </a> */}


                            </div>
                        </div>
                    </div>
                </section>

                <div className='second_home_section' ref={sliderRef}>
                    <div className='wrapper_2'>
                        <section className='work-section bottom' id='horizontal' style={{ overflow: 'hidden' }}>
                            <div className='container-fluid'>
                                <div className='header-title'>
                                    <h3 class="reveal-type-4">Key Features and our services</h3>
                                </div>
                                <div className='slider_box'>
                                    <Link to={'/website-development'} className='horizontal__item'>
                                        <div className='card_box'>
                                            <div className='logo_icon'>
                                                {/* <img src={require('../assets/img/icon01.png')} /> */}
                                                <Lottie
                                                    options={{
                                                        loop: true,
                                                        autoplay: true,
                                                        animationData: require("../assets/lotti/web.json"),
                                                        rendererSettings: {
                                                            preserveAspectRatio: "xMidYMid slice"
                                                        }
                                                    }}
                                                    height={'70%'}
                                                    width={'90%'}
                                                />
                                            </div>
                                            <div className='card-detail-box'>
                                                <h3>Website Development</h3>
                                                <p>Web & Mobile Sites User Interface Design Responsive Techs.</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={"/mobile-app-development"} className='horizontal__item'>
                                        <div className='card_box'>
                                            <div className='logo_icon'>
                                                <Lottie
                                                    options={{
                                                        loop: true,
                                                        autoplay: true,
                                                        animationData: require("../assets/lotti/app.json"),
                                                        rendererSettings: {
                                                            preserveAspectRatio: "xMidYMid slice"
                                                        }
                                                    }}
                                                    height={'70%'}
                                                    width={'90%'}
                                                />
                                            </div>
                                            <div className='card-detail-box'>
                                                <h3>Mobile App Development</h3>
                                                <p>Get handy solutions to bring your project to life.</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={"/aws-hosting"} className='horizontal__item'>
                                        <div className='card_box'>
                                            <div className='logo_icon'>
                                                <Lottie
                                                    options={{
                                                        loop: true,
                                                        autoplay: true,
                                                        animationData: require("../assets/lotti/server.json"),
                                                        rendererSettings: {
                                                            preserveAspectRatio: "xMidYMid slice"
                                                        }
                                                    }}
                                                    height={'70%'}
                                                    width={'90%'}
                                                />
                                            </div>
                                            <div className='card-detail-box'>
                                                <h3>AWS Hosting</h3>
                                                <p>Live your project to the scalable environment.</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={"/ui-ux-design"} className='horizontal__item'>
                                        <div className='card_box'>
                                            <div className='logo_icon'>
                                                <Lottie
                                                    options={{
                                                        loop: true,
                                                        autoplay: true,
                                                        animationData: require("../assets/lotti/ui.json"),
                                                        rendererSettings: {
                                                            preserveAspectRatio: "xMidYMid slice"
                                                        }
                                                    }}
                                                    height={'70%'}
                                                    width={'90%'}
                                                />
                                            </div>
                                            <div className='card-detail-box'>
                                                <h3>UI/UX Design</h3>
                                                <p>Get your brand's sight even before coding.</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={"/digital-marketing"} className='horizontal__item'>
                                        <div className='card_box'>
                                            <div className='logo_icon'>
                                                <Lottie
                                                    options={{
                                                        loop: true,
                                                        autoplay: true,
                                                        animationData: require("../assets/lotti/digital.json"),
                                                        rendererSettings: {
                                                            preserveAspectRatio: "xMidYMid slice"
                                                        }
                                                    }}
                                                    height={'70%'}
                                                    width={'90%'}
                                                />
                                            </div>
                                            <div className='card-detail-box'>
                                                <h3>Digital Marketing</h3>
                                                <p>Promote and get ranks for your brand to the higher level.</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={"/graphic-designing"} className='horizontal__item'>
                                        <div className='card_box'>
                                            <div className='logo_icon'>
                                                <Lottie
                                                    options={{
                                                        loop: true,
                                                        autoplay: true,
                                                        animationData: require("../assets/lotti/graphic.json"),
                                                        rendererSettings: {
                                                            preserveAspectRatio: "xMidYMid slice"
                                                        }
                                                    }}
                                                    height={'70%'}
                                                    width={'90%'}
                                                />
                                            </div>
                                            <div className='card-detail-box'>
                                                <h3>Graphic Designing</h3>
                                                <p>Cool and creative Logo, Images, Social media posts.</p>
                                            </div>
                                        </div>
                                    </Link>

                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <section className='subscribe-newslatter'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='left-section'>
                                    Subscribe to our newsletter
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='testimonials-section top bottom'>
                    <div class="container-fluid">
                        <div class="row mb-70">
                            <div class="col-lg-12">
                                <div class="heading-title center">
                                    <span class="sub-title text-blue1 font-man">Our Testimonials</span>
                                    <h2 class="heading">Clients Say <span class="text-blue1">About Us</span></h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 relative">
                                <div class="myteshome5-wrap overflow-hiden">
                                    <Swiper
                                        spaceBetween={0}
                                        centeredSlides={true}
                                        loop={true}
                                        // autoplay={{
                                        //     delay: 2500,
                                        //     disableOnInteraction: false,
                                        // }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={false}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        className="mySwiper testimonials-slider"
                                    >
                                        <SwiperSlide>
                                            <div class="testimonial-style3 style3-h5 flex-three">
                                                {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                                <div class="content">
                                                    <p class="des">Makemywebsite transformed our online presence. The team’s dedication and creativity exceeded our expectations!</p>
                                                    <div class="testimonial-bottom flex-two">
                                                        <div class="testimonial-top flex-three">
                                                            <i class="fa-solid fa-quote-left"></i>
                                                            <p class="name">Emily Carter, <span class="job font-man">Marketing Manager, GreenTech Solutions</span></p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="testimonial-style3 style3-h5 flex-three">
                                                {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                                <div class="content">
                                                    <p class="des">Incredible service and flawless execution! My website looks amazing, and the process was seamless.</p>
                                                    <div class="testimonial-bottom flex-two">
                                                        <div class="testimonial-top flex-three">
                                                            <i class="fa-solid fa-quote-left"></i>
                                                            <p class="name">John Davis, <span class="job font-man">Founder, Urban Styles Co.</span></p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="testimonial-style3 style3-h5 flex-three">
                                                {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                                <div class="content">
                                                    <p class="des">Makemywebsite understood our needs perfectly. The result is a website that represents our brand beautifully.</p>
                                                    <div class="testimonial-bottom flex-two">
                                                        <div class="testimonial-top flex-three">
                                                            <i class="fa-solid fa-quote-left"></i>
                                                            <p class="name">Sophia Lee, <span class="job font-man">Creative Director, Blossom Events</span></p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="testimonial-style3 style3-h5 flex-three">
                                                {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                                <div class="content">
                                                    <p class="des">The team at Makemywebsite is highly professional and talented. I highly recommend their services!</p>
                                                    <div class="testimonial-bottom flex-two">
                                                        <div class="testimonial-top flex-three">
                                                            <i class="fa-solid fa-quote-left"></i>
                                                            <p class="name">Rajesh Kumar, <span class="job font-man">CEO, TechSphere Innovations</span></p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="testimonial-style3 style3-h5 flex-three">
                                                {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                                <div class="content">
                                                    <p class="des">Their attention to detail and quick turnaround time made the entire experience delightful.</p>
                                                    <div class="testimonial-bottom flex-two">
                                                        <div class="testimonial-top flex-three">
                                                            <i class="fa-solid fa-quote-left"></i>
                                                            <p class="name">Lisa Brown, <span class="job font-man">Owner, HomeNest Interiors</span></p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="testimonial-style3 style3-h5 flex-three">
                                                {/* <div class="image"><img src={require('../assets/img/features-2-2.jpg')} alt="image" /></div> */}
                                                <div class="content">
                                                    <p class="des">Makemywebsite delivered exactly what we needed—a sleek, responsive website that drives traffic!</p>
                                                    <div class="testimonial-bottom flex-two">
                                                        <div class="testimonial-top flex-three">
                                                            <i class="fa-solid fa-quote-left"></i>
                                                            <p class="name">Michael Grant, <span class="job font-man">Head of Sales, Apex Automotive</span></p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="video-section bottom">
                    <div class="bg-layer"></div>
                    <div class="pattern-layer-2"></div>
                    <div class="auto-container">
                        <div class="content-box">
                            <div class="text-box">
                                <h2>Featured <span>Works</span>
                                    <i class="fa-solid fa-arrow-up-long icon-2"></i>
                                </h2>
                            </div>
                            <div class="curve-text">
                                <div class="icon-box">
                                    <Link to="/contact" class="lightbox-image" data-caption="">
                                        Contact Us
                                    </Link>
                                </div>
                                <div class="shape"></div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className='about-section-inner-1 p-l-r top bottom'>
                    <div className='container-fluid'>
                        <div className='row'>

                            <div className='col-lg-6'>
                                <div className='right-section'>
                                    <div className='img_left_d'>
                                        <img src={require('../assets/img/home-img.jpg')} />
                                    </div>
                                    <div className='shap_box'>
                                        <img src={require('../assets/img/shape-16.png')} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='left-section'>
                                    <h4 className='sub-title'>Why Choose Us?</h4>
                                    <h3 className='heading'>
                                        What We Do The Best
                                    </h3>
                                    <ul>
                                        <li>
                                            <h4>Client-Centric Approach</h4>
                                            <p>We believe every client has a story, and we aim to reflect that through bespoke website designs.</p>
                                        </li>
                                        <li>
                                            <h4>Expert Team</h4>
                                            <p>Our experienced team stays up-to-date with the latest trends and technologies to ensure your website stands out.</p>
                                        </li>
                                        <li>
                                            <h4>Affordable Solutions</h4>
                                            <p>Quality doesn't have to break the bank. We offer cost-effective services without compromising on excellence.</p>
                                        </li>
                                        <li>
                                            <h4>Support You Can Count On</h4>
                                            <p>From the initial design phase to post-launch support, we’re with you every step of the way.</p>
                                        </li>

                                    </ul>
                                    <Link to='/contact' className='common_btn'>
                                        Contact Us <span><i class="fa-solid fa-arrow-up-long"></i></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer setLoading={setLoading} />
            </ReactLenis>

        </>
    )
}
