import React, { useEffect, useMemo, useRef, useState } from 'react'
import Lottie from 'react-lottie';
import * as Colors from '../Utils/Color'
import animationData from '../assets/lotti/web-app.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import Lenis from '@studio-freight/lenis'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { Grid, Pagination } from 'swiper/modules';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import SplitType from 'split-type'
import { Link } from 'react-router-dom';
import Loader from '../component/Loader';
import Footer from '../component/Footer';

gsap.registerPlugin(useGSAP, ScrollTrigger);


export default function Work() {
    const [activeTab, setActiveTab] = useState('all');
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);

    const main = useRef();
    const banner = useRef();
    const about = useRef();
    const sliderRef = useRef(null);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    var tl = gsap.timeline({ repeat: 2, repeatDelay: 1 });
    tl.to(".box", { x: -600, duration: 1 }, 2);

    useGSAP(() => {
        let tl = gsap.timeline()
        tl.to('.banner-section .left-section h2', { 'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)', opacity: 1, x: 0, skewX: 0, duration: 2, delay: 1.4, autoAlpha: 1 })
        tl.from('.paragraph-b', { opacity: 0, y: window?.innerWidth < 768 ? 40 : 100, duration: window?.innerWidth < 768 ? 0.1 : 2, delay: window?.innerWidth < 768 ? 0 : 1.2, autoAlpha: 1 })
        const splitTypes1 = document.querySelectorAll('.reveal-type-3')
        splitTypes1.forEach((char, i) => {

            const bg = char.dataset.bgColor
            const fg = char.dataset.fgColor

            const text = new SplitType(char, { types: 'chars' })

            gsap.from(text.chars,
                {
                    opacity: 0,
                    stagger: 0.01,
                    delay: window?.innerWidth < 768 ? 1 : 4
                })
        })
        const ba = gsap.timeline({ scrollTrigger: { scrub: 1, trigger: ".home_page_wrap", start: window?.innerWidth < 768 ? "top 90%" : "top 0%", end: window?.innerWidth < 768 ? "bottom 50%" : "bottom 0%" } });

        ba.fromTo('.about-section', { y: window?.innerWidth < 576 ? 0 : 800, }, { y: 0, duration: 1 }, window.innerWidth < 768 ? 0.1 : 0.5);

        const splitTypes = document.querySelectorAll('.reveal-type');
        const splitTypeszoom = document.querySelectorAll('.zoom-animtion');
        const splitTypes_1 = document.querySelectorAll('.reveal-type-1');
        const splitTypes2 = document.querySelectorAll('.reveal-type-2');

        // splitTypeszoom.forEach((char, i) => {
        //     const bg = char.dataset.bgColor;
        //     const fg = char.dataset.fgColor;

        //     const text = new SplitType(char, { types: "chars" });

        //     ba.to(text.chars, {
        //         scale: 50,
        //         delay: 0.2,
        //         duration: 0.1,
        //         opacity: 1,
        //     }, window.innerWidth < 768 ? 1:3.5);
        // });

        splitTypes.forEach((char, i) => {
            const bg = char.dataset.bgColor;
            const fg = char.dataset.fgColor;

            const text = new SplitType(char, { types: "chars" });

            ba.to(text.chars, {
                x: 0,
                skewX: 0,
                stagger: 0.02,
                delay: 0.2,
                duration: 0.1,
                opacity: 1,
            }, window.innerWidth < 768 ? -0.5 : 1);
        });



        splitTypes2.forEach((char, i) => {

            const bg = char.dataset.bgColor
            const fg = char.dataset.fgColor

            const text = new SplitType(char, { types: 'chars' })

            ba.from(text.chars,
                {

                    scaleY: 0,
                    y: -30,
                    transformOrigin: 'top',
                    stagger: 0.01,
                }, window.innerWidth < 768 ? 0.2 : 1)
        })
        splitTypes_1.forEach((char, i) => {

            const bg = char.dataset.bgColor
            const fg = char.dataset.fgColor

            const text = new SplitType(char, { types: 'chars' })

            ba.from(text.chars,
                {
                    opacity: window?.innerWidth < 768 ? 1 : 0,
                    stagger: 0.01,
                }, window.innerWidth < 768 ? 0.2 : 0.5)
        })

    }
    )

    useGSAP(
        () => {
            const paths = [...document.querySelectorAll('path.path-anim')];
            paths.forEach(el => {
                const svgEl = el.closest('svg');
                const pathTo = el.dataset.pathTo;

                gsap.timeline({
                    scrollTrigger: {
                        trigger: svgEl,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: true
                    }
                })
                    .to(el, {
                        ease: 'none',
                        attr: { d: pathTo }
                    });
            });
            const section_1 = document.getElementById("vertical");
            const col_left = document.querySelector(".col_left");
            const timeln = gsap.timeline({
                paused: true
            });
            timeln.fromTo(col_left, {
                y: 0
            },
                {
                    y: '140vh',
                    duration: 1,
                    ease: 'power1.inOut'
                }, 0);
            const scroll_1 = ScrollTrigger.create({
                animation: timeln,
                trigger: section_1,
                start: 'top top',
                end: 'bottom center',
                scrub: true
            });

            const profolio_item = gsap.utils.toArray('.profolio-item');
            profolio_item.forEach((gsapItem) => {
                const img_box_main = gsapItem.querySelector('.img_box_main');
                const img_box = gsapItem.querySelector('.img_box');
                const img = gsapItem.querySelector('.img_box img');
                const h3 = gsapItem.querySelector('.profolio-item h3');
                const h5 = gsapItem.querySelector('.profolio-item h5');
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: gsapItem,
                        start: 'top 70%',
                        toggleActions: 'play none none none',
                        // markers: true
                    }
                })
                tl.from(h3, 1, { opacity: 0, translateX: '500px', ease: "expo.out" })
                tl.from(h5, 1, { opacity: 0, translateX: '500px', ease: "expo.out" })
                tl.to(img_box_main, 1, { clipPath: "polygon(0% 0%,100% 0%,100%  100%, 0% 100% )", ease: 'expo.out' }, "-=1")
                tl.to(img_box, 1.5, { clipPath: "polygon(0% 0%,100% 0%,100%  100%, 0% 100% )", ease: 'expo.out' }, "-=0.5")
                tl.from(img, 4, { scale: 1.4, filter: "blur(15px)", ease: 'expo.out' }, "-=1.7")

                let tl1 = gsap.timeline({
                    scrollTrigger: {
                        trigger: '.about-section',
                        start: 'top 70%',

                    }
                })
                tl1.from('.about-section h2', 1, { y: 50, direction: 1, ease: 'power1.out' })


            })


        },
        { scope: main }
    );



    const handleChange = (e) => {
        setValue(e.target.value)
        // if(e.target.value > 0 )
    }


    return (
        <>
            <ReactLenis
                root
                options={{ gestureOrientataion: "both" }}
            >
                <Loader visible={loading} />
                <section className='inner-banner-section p-l-r'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='left-section'>
                                    <h1>Work</h1>
                                    <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">*</li>
                                        <li className="breadcrumb-item active" aria-current="page">Work</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section ref={main} className='portfolio-sect padding-left-right top bottom' id="vertical">
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <div className='heading-title col_left'>
                                    <h3 class="reveal-type-1">Portfolio</h3>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                {/* <a href='#'> */}
                                    <div className='profolio-item  right_padding'>
                                        <div className='img_box_main'>
                                            <div className='img_box'>
                                                <img src={require("../assets/gif/design.gif")} />
                                            </div>
                                        </div>
                                        <h3 class="reveal-type-1">Bespoke Designs</h3>
                                        <h5 class="reveal-type-2">Get unique, tailor-made designs with spacial effects</h5>
                                    </div>
                                {/* </a> */}
                                {/* <a href='#'> */}
                                    <div className='profolio-item single-box right_padding'>
                                        <div className='img_box_main'>
                                            <div className='img_box'>
                                                <img src={require("../assets/gif/seo.gif")} />
                                            </div>
                                        </div>
                                        <h3 class="reveal-type-1">SEO Optimized</h3>
                                        <h5 class="reveal-type-2">Ensure your website ranks</h5>
                                    </div>
                                {/* </a> */}
                                {/* <a href='#'> */}
                                    <div className='profolio-item full-box right_padding'>
                                        <div className='img_box_main'>
                                            <div className='img_box'>
                                                <img src={require("../assets/gif/crm.gif")} />
                                            </div>
                                        </div>
                                        <h3 class="reveal-type-1">Custom Software Solutions</h3>
                                        <h5 class="reveal-type-2">Appointments, Bookings, Management</h5>
                                    </div>
                                {/* </a> */}


                            </div>
                            <div className='col-lg-5'>
                                {/* <a href='#'> */}
                                    <div className='profolio-item single-box first-box padding_left'>
                                        <div className='img_box_main'>
                                            <div className='img_box'>
                                                <img src={require("../assets/gif/fullstack.gif")} />
                                            </div>
                                        </div>
                                        <h3 class="reveal-type-1">Functionality</h3>
                                        <h5 class="reveal-type-2">Full-Stack Development</h5>
                                    </div>
                                {/* </a> */}
                                {/* <a href='#'> */}
                                    <div className='profolio-item full-box padding_left'>
                                        <div className='img_box_main'>
                                            <div className='img_box'>
                                                <img src={require("../assets/gif/mobile.gif")} />
                                            </div>
                                        </div>
                                        <h3 class="reveal-type-1">Mobile App Development</h3>
                                        <h5 class="reveal-type-2">App for both Android and iOS.</h5>
                                    </div>
                                {/* </a> */}
                                {/* <a href='#'> */}
                                    <div className='profolio-item single-box padding_left'>
                                        <div className='img_box_main'>
                                            <div className='img_box'>
                                                <img src={require("../assets/gif/dynamic.gif")} />
                                            </div>
                                        </div>
                                        <h3 class="reveal-type-1">Dynamic Content</h3>
                                        <h5 class="reveal-type-2">Admin Panel to manage the web or app contents</h5>
                                    </div>
                                {/* </a> */}


                            </div>
                        </div>
                    </div>
                </section>


                <Footer setLoading={setLoading} />
            </ReactLenis>

        </>
    )
}
